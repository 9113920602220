import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState, AppThunk } from "./store";
import { getCompanyList } from "./api/coreAPI";
//import { Interface } from "readline";
import { GetCompanyInterface } from "../interfaces/company.interfaces";

export interface StateInterface {
  coreStatus: "" | "fulfilled" | "success" | "loading" | "failed" | "error";
  coreMessage: string;
  companyList: {};
}

const initialState: StateInterface = {
  coreStatus: "loading",
  coreMessage: "",
  companyList: {},
};

export const getCompanies = createAsyncThunk(
  "company/companies",
  async (data: GetCompanyInterface) => {
    const response = await getCompanyList(data);
    return response.data;
  }
);

export const companySlice = createSlice({
  name: "company",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    clearCompany: (state) => {
      state.coreStatus = "";
      state.coreMessage = "";
      state.companyList = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCompanies.pending, (state) => {
        state.coreStatus = "loading";
      })
      .addCase(getCompanies.fulfilled, (state, action) => {
        state.coreStatus = "fulfilled";
        console.log("action.payload", action);
        if (action.payload.status === 404) {
          state.coreStatus = "error";
        } else {
          console.log("state", action.payload);
          state.companyList = action.payload;
        }
      })
      .addCase(getCompanies.rejected, (state, action) => {
        console.log("action.api.rejected1", action.error.message);
        state.coreStatus = "error";
        state.coreMessage = action.error.message || "";
        console.log("action.api.rejected2", action);
      });
  },
});

export const { clearCompany } = companySlice.actions;

export const coreStatus = (state: RootState) => state.company.coreStatus;
export const coreMessage = (state: RootState) => state.company.coreMessage;
export const companyList = (state: RootState) => state.company.companyList;
export default companySlice.reducer;
