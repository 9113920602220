import React, { useEffect } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import AdbIcon from "@mui/icons-material/Adb";
import Constants from "../../../config/Constants";
import { BorderBottom } from "@mui/icons-material";

const componentStyles = {
  selectedMenuButton: {
    my: 2,
    color: "#9c27b0",
    display: "block",
    borderBottom: 3,
    borderColor: "#9c27b0",
    borderRadius: 0,
  },
  menuButton: {
    my: 2,
    color: "black",
    display: "block",
  },
};
const pages = [
  { name: "All Companies", value: "All" },
  { name: "Prime Vendors", value: "Prime Vendor" },
  { name: "Sub Contractors", value: "Sub Contractor" },
  { name: "Staffing", value: "Staffing" },
  { name: "Implementation Partners", value: "Implementation Partner" },
];
const settings = ["Profile", "Account", "Dashboard", "Logout"];

function TopAppBar(props: any) {
  const { onValueSelect, selectedValue } = props;
  const [selectedMenu, setSelectedMenu] = React.useState("All");

  useEffect(() => {
    try {
      setSelectedMenu(selectedValue);
    } catch (e) {}
  }, [selectedValue]);

  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(
    null
  );
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null
  );

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
    //handleMenuChange(value);
  };

  const handleMenuChange = (value: any) => {
    setSelectedMenu(value);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <AppBar position="fixed" sx={{ color: "black", backgroundColor: "white" }}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          {/* <AdbIcon sx={{ display: { xs: "none", md: "flex" }, mr: 1 }} /> */}
          <Typography
            variant="h6"
            noWrap
            component="a"
            href="#app-bar-with-responsive-menu"
            sx={{
              mr: 0,
              display: { xs: "none", md: "flex" },
              fontFamily: "monospace",
              fontSize: "2.3rem",
              fontWeight: 700,
              letterSpacing: ".3rem",
              color: "black",
              textDecoration: "none",
            }}
          >
            {Constants.productName}
          </Typography>

          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="default"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{ color: "black", display: { xs: "block", md: "none" } }}
            >
              {pages.map((page) => (
                <MenuItem
                  sx={{ color: "black" }}
                  key={page.value}
                  selected={page.value === selectedMenu ? true : false}
                  onClick={() => {
                    onValueSelect(page.value);
                    handleCloseNavMenu();
                    handleMenuChange(page.value);
                  }}
                  //onClick={() => handleCloseNavMenu(page)}
                >
                  <Typography sx={{ color: "black", textAlign: "center" }}>
                    {page.name}
                  </Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
          {/* <AdbIcon
            sx={{ color: "black", display: { xs: "flex", md: "none" }, mr: 1 }}
          /> */}
          <Typography
            variant="h5"
            noWrap
            component="a"
            href="#app-bar-with-responsive-menu"
            sx={{
              mr: 2,
              display: { xs: "flex", md: "none" },
              flexGrow: 1,
              fontFamily: "monospace",
              fontSize: "2.3rem",
              fontWeight: 700,
              letterSpacing: ".3rem",
              color: "black",
              textDecoration: "none",
            }}
          >
            {Constants.productName}
          </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
            {pages.map((page) => (
              <Button
                key={page.value}
                onClick={() => {
                  onValueSelect(page.value);
                  handleCloseNavMenu();
                  handleMenuChange(page.value);
                }}
                //onClick={() => handleCloseNavMenu(page)}
                sx={
                  page.value === selectedMenu
                    ? componentStyles.selectedMenuButton
                    : componentStyles.menuButton
                }
              >
                {page.name}
              </Button>
            ))}
          </Box>
          {/* <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Open settings">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar alt="Remy Sharp" src="/static/images/avatar/2.jpg" />
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: "45px" }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {settings.map((setting) => (
                <MenuItem key={setting} onClick={handleCloseUserMenu}>
                  <Typography sx={{ textAlign: "center" }}>
                    {setting}
                  </Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box> */}
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default TopAppBar;
