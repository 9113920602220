import React, { useEffect } from "react";
import { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import styles from "./HomeLayout.module.css";
import TopAppBar from "./TopAppBar";
import { Outlet } from "react-router-dom";
import { OutletContextType } from "../../../interfaces/company.interfaces";
import { initializeClient, apiInitialized } from "../../../redux/userSlice";

const HomeLayoutRoot = styled("div")(({ theme }) => ({
  display: "flex",
  flex: "1 1 auto",
  maxWidth: "100%",
  color: "black",
  backgroundColor: "white",
  paddingTop: 10,
  [theme.breakpoints.up("lg")]: {
    paddingLeft: 0,
  },
}));

export const HomeLayout = (props: any) => {
  const { children } = props;
  const dispatch = useAppDispatch();
  const isApiInitialized = useAppSelector(apiInitialized);
  const [isSidebarOpen, setSidebarOpen] = useState(true);
  const [selectedValue, setSelectedValue] = React.useState("");
  useEffect(() => {
    console.log("isApiInitialized 1:", isApiInitialized);
    try {
      if (!isApiInitialized) {
        console.log("dispatch");
        dispatch(initializeClient());
      }
    } catch (e) {}
  }, []);

  const handleValueSelect = (value: string) => {
    console.log("handleValueSelect", value);
    setSelectedValue(value);
  };

  const contextValue: OutletContextType = {
    selectedValue,
    onValueSelect: handleValueSelect,
  };

  // context={{ selectedValue }}

  return (
    <>
      <HomeLayoutRoot>
        <Box className={styles.mainContainer}>
          <Outlet context={contextValue} />
        </Box>
        <TopAppBar
          onValueSelect={handleValueSelect}
          selectedValue={selectedValue}
        />
      </HomeLayoutRoot>
    </>
  );
};
