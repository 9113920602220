import axios, { AxiosPromise } from "axios";
import Config from "../../config/Config";
import { GetCompanyInterface } from "../../interfaces/company.interfaces";

const coreAPIService = axios.create();
const basePath = `${Config().envConfigModule.serverAPI}`;

export const getCompanyList = (data: GetCompanyInterface): AxiosPromise => {
  console.log("data", data);
  console.log("basePath", basePath);
  let query = `?pageSize=${data.pageSize}&page=${data.page}`;
  if (data.search) {
    query += `&search=${data.search}`;
  }
  if (data.category) {
    query += `&category=${data.category}`;
  }
  if (data.orderByField) {
    query += `&orderByField=${data.orderByField}&sortBy=${data.sortBy}`;
  }
  console.log("query:::::::", query);
  const config = {
    method: "get",
    url: `${basePath}/company/companies${query}`,
    headers: {
      "Content-Type": "application/json",
      apiKey: data.apiKey,
    },
    //data: data,
  };

  return coreAPIService(config);
};
