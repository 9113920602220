const Contants = {
  drawerWidth: 240,
  emailRegEx: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
  errors: {
    UserDoesNotExist:
      "User does not exist or username and password dont match.",
  },
  productName: "QRADE",
  validations: {
    signUp: {
      firstName: "Please enter first name",
      lastName: "Please enter last name",
      email: "Please enter a valid email address",
      password: "Password cannot be less than 8 characters",
      retypePwd: "Password does not match",
      agreement:
        "Please read and accept the Terms of Service and Privacy Policy",
    },
  },
};

export default Contants;
