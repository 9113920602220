import React, { useEffect } from "react";
import {
  Grid,
  Paper,
  Card,
  CardContent,
  Stack,
  Typography,
  Button,
  Rating,
  Chip,
  Avatar,
  Pagination,
} from "@mui/material";
import { deepOrange, blueGrey } from "@mui/material/colors";
import styles from "./MainContainer.module.css";
import "./MainContainer.module.css";
import Container from "@mui/material/Container";
import { Height, Margin } from "@mui/icons-material";
import AnchorIcon from "@mui/icons-material/Anchor";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import {
  coreStatus,
  coreMessage,
  companyList,
  getCompanies,
} from "../../../redux/companySlice";
import CustomSearch from "../../../components/CustomSearch";
import { apiToken, apiInitialized } from "../../../redux/userSlice";
import { GetCompanyInterface } from "../../../interfaces/company.interfaces";
import { useOutletContext } from "react-router-dom";
import { OutletContextType } from "../../../interfaces/company.interfaces";
import CompanyCard from "./CompanyCard";

const componentStyles = {
  titleCard: {
    padding: 2,
    display: "flex",
    flexDirection: "column",
    marginTop: 9,
    backgroundColor: "var(--primaryColor)",
  },
  titleTypography: {
    paddingTop: 0,
    color: "black",
    textAlign: "center",
    alignContent: "center",
  },
  listContainer: {
    p: 2,
    display: "flex",
    flexDirection: "column",
    height: 940,
    backgroundColor: "#faf9f8",
  },
  content: {
    //display: "flex",
    //alignItems: "center",
    minWidth: 275,
    minHeight: 380,
    backgroundColor: "white",
    border: 1,
    marginBottom: 1,
  },
  logoIcon: {
    marginLeft: 2,
    marginTop: 2,
    marginBottom: 1,
    //marginRight: 1,
  },
  name: {
    fontWeight: "bold",
    marginLeft: 1,
    marginTop: 2,
    marginBottom: 1,
  },
  website: {
    marginLeft: 1,
    marginTop: 2,
    marginBottom: 1,
  },
  websiteButton: {
    width: 100,
  },
  chipContainer: {
    flexWrap: "wrap",
    maxWidth: 500,
  },
  companyAvatar: {
    bgcolor: blueGrey[700],
    backgroundSize: "cover",
    backgroundPosition: "top center",
    border: 1,
    "& img": {
      objectFit: "contain", // Ensures the image fits well inside the avatar
    },
  },
  companyCardContainer: {
    //display: "flex",
    //alignItems: "center",
    minWidth: 275,
    minHeight: {
      xs: 380,
      sm: 220,
      md: 220,
      lg: 220,
    },
    backgroundColor: "white",
    border: 1,
    marginBottom: 1,
  },
  categoryChip: {
    minWidth: 100,
    //width: 100,
    //margin: "1px",
    marginBottom: 1,
    marginRight: 1,
    backgroundColor: "#f6e2ed", // blueGrey[200],
    fontSize: 11,
    fontWeight: "bold",
    color: "black",
    borderRadius: "5px",
  },
  serviceChip: {
    minWidth: 100,
    width: 100,
    marginBottom: 1,
    marginRight: 1,
    borderRadius: "5px",
    fontSize: 11,
    fontWeight: "bold",
    color: "black",
  },
  search: {
    paddingLeft: "15px",
  },
  noDataContent: {
    alignContent: "center",
    TextAlign: "center",
    alignText: "center",
    minWidth: 275,
    minHeight: 80,
    backgroundColor: "inherit",
    border: 1,
    marginBottom: 1,
  },
};

function MainContainer(props: any) {
  const dispatch = useAppDispatch();
  const companyListData = useAppSelector(companyList);
  const isApiInitialized = useAppSelector(apiInitialized);
  const apiTokenKey = useAppSelector(apiToken);
  //const companyListData = useAppSelector(companyList);
  const { selectedValue, onValueSelect } =
    useOutletContext<OutletContextType>();

  const [currentPage, setCurrentPage] = React.useState(1);
  const [pageSizeLimit, setPageSizeLimit] = React.useState(10);
  const [sortByField, setSortByField] = React.useState("");
  const [orderBy, setOrderBy] = React.useState("");

  const [searchText, setSearchText] = React.useState("");
  const [selectedCategory, setSelectedCategory] = React.useState(selectedValue);

  // console.log("isApiInitialized 2:", isApiInitialized);
  // console.log("apiTokenKey 2:", apiTokenKey);
  // console.log("companyListData:", companyListData);

  // console.log("selectedValue", selectedValue);

  useEffect(() => {
    try {
      console.log("companyListData", companyListData);
      console.log("apiInitialized", apiInitialized);
      if (isApiInitialized) {
        let params: GetCompanyInterface = {
          apiKey: apiTokenKey,
          page: currentPage,
          pageSize: pageSizeLimit,
          sortBy: sortByField,
          orderByField: orderBy,
          search: searchText,
          category: selectedValue ? selectedValue : "",
        };
        dispatch(getCompanies(params));
      }
    } catch (e) {}
  }, [isApiInitialized]);

  useEffect(() => {
    try {
      console.log("companyListData", companyListData);
      console.log("apiInitialized", apiInitialized);
      if (isApiInitialized && selectedValue) {
        let params: GetCompanyInterface = {
          apiKey: apiTokenKey,
          page: currentPage,
          pageSize: pageSizeLimit,
          sortBy: sortByField,
          orderByField: orderBy,
          search: "",
          category:
            selectedValue && selectedValue != "All" ? selectedValue : "",
        };
        dispatch(getCompanies(params));
      }
    } catch (e) {}
  }, [selectedValue]);

  const handleCategoryClick = (value: string) => {
    setSelectedCategory(value);
  };

  const onPageChange = (event: any, value: any) => {
    console.log("onPageChange", value);
    setCurrentPage(value);
    let params: GetCompanyInterface = {
      apiKey: apiTokenKey,
      page: value,
      pageSize: pageSizeLimit,
      sortBy: sortByField,
      orderByField: orderBy,
      search: searchText,
      category: selectedCategory ? selectedCategory : "",
    };
    dispatch(getCompanies(params));
    event.preventDefault();
  };

  const onSearchChange = (event: any, value: any) => {
    console.log("onSearchChange", event);
    console.log("onSearchChange value", value);
    onValueSelect("");
    setSearchText("");
    let params: GetCompanyInterface = {
      apiKey: apiTokenKey,
      page: currentPage,
      pageSize: pageSizeLimit,
      sortBy: sortByField,
      orderByField: orderBy,
      search: value,
      category: "",
    };
    dispatch(getCompanies(params));
    event.preventDefault();
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={12} lg={12}>
        <Card sx={componentStyles.titleCard}>
          <Typography
            sx={componentStyles.titleTypography}
            variant="h3"
            component="div"
          >
            Find the right consulting company for any contract roles
          </Typography>
        </Card>
      </Grid>
      <Grid item xs={12} md={12} lg={12}>
        <div style={componentStyles.search}>
          <CustomSearch onSearchChange={onSearchChange} />
        </div>
        <Paper sx={componentStyles.listContainer}>
          {companyListData &&
          companyListData.data &&
          companyListData.data.length > 0 ? (
            companyListData.data.map((company: any) => {
              const dataCategory = company.category
                ? company.category.split(",")
                : [];
              //console.log("dataCategory", dataCategory);
              return (
                <>
                  {/* <CompanyCard data={company} /> */}
                  <Card sx={componentStyles.companyCardContainer}>
                    <Stack spacing={{ xs: 0, sm: 0 }}>
                      <Stack direction="row" spacing={0}>
                        <Stack
                          className={styles.companyIconContainer}
                          sx={componentStyles.logoIcon}
                        >
                          <Avatar
                            sx={componentStyles.companyAvatar}
                            variant="rounded"
                            key={company.name}
                            src={company.logo ? company.logo : ""}
                          >
                            {company.name && company.name.substring(0, 1)}
                          </Avatar>
                        </Stack>
                        <Stack
                          className={styles.companyNameContainer}
                          sx={componentStyles.name}
                        >
                          {company.name} <br />
                          <Rating name="read-only" value={null} readOnly />
                        </Stack>
                        <Stack
                          className={styles.companyButtonContainer}
                          sx={componentStyles.website}
                        >
                          <Button
                            //className={styles.companyButton}
                            sx={componentStyles.websiteButton}
                            variant="outlined"
                            size="large"
                            key={company.website}
                            href={company.website}
                            target="_blank"
                          >
                            {"Website"}
                          </Button>
                        </Stack>
                      </Stack>
                    </Stack>
                    <Stack direction="row" spacing={0}>
                      <Stack className={styles.companyDetailsContainer}>
                        <Typography variant="body2" gutterBottom>
                          {company.details}
                        </Typography>
                      </Stack>
                    </Stack>
                    {company.state && (
                      <Stack direction="row" spacing={0}>
                        <Stack>
                          <Typography variant="body2" gutterBottom>
                            <div className={styles.locationContainer}>
                              <LocationOnIcon /> {company.city}, {company.state}
                            </div>
                          </Typography>
                        </Stack>
                      </Stack>
                    )}
                    {/* <Stack direction="row" spacing={0}> */}
                    <Stack
                      direction="row"
                      spacing={0}
                      sx={componentStyles.chipContainer}
                      className={styles.companyCategoryContainer}
                    >
                      {company.category &&
                        company.category.split(",").map((value: any) => {
                          return (
                            <Chip
                              sx={componentStyles.categoryChip}
                              label={value}
                              key={value}
                              size="small"
                              //color="warning"
                              //variant="outlined"
                            />
                          );
                        })}
                      {company.services &&
                        company.services.split(",").map((value: any) => {
                          return (
                            <Chip
                              sx={componentStyles.serviceChip}
                              key={value}
                              label={value}
                              size="small"
                              color="primary"
                              variant="outlined"
                            />
                          );
                        })}
                    </Stack>
                    {/* <Stack direction="row" spacing={1}>
                        {company.services &&
                          company.services.split(",").map((value: any) => {
                            return (
                              <Chip
                                sx={componentStyles.chip}
                                key={value}
                                label={value}
                                size="small"
                                color="primary"
                                variant="outlined"
                              />
                            );
                          })}
                      </Stack> */}
                    {/* </Stack> */}
                  </Card>

                  {/* <Card sx={componentStyles.content}>
                    <Stack spacing={{ xs: 0, sm: 0 }}>
                      <Grid container spacing={0}>
                        <Grid
                          sx={componentStyles.logoIcon}
                          item
                          xs={1}
                          sm={1}
                          md={1}
                          lg={1}
                        >
                          <Avatar
                            sx={{ bgcolor: blueGrey[700] }}
                            variant="rounded"
                            key={company.name}
                          >
                            {company.name && company.name.substring(0, 1)}
                          </Avatar>
                        </Grid>
                        <Grid
                          sx={componentStyles.name}
                          item
                          xs={4}
                          sm={4}
                          md={4}
                          lg={4}
                        >
                          {company.name} <br />
                          <Rating name="read-only" value={null} readOnly />
                        </Grid>
                        <Grid
                          sx={componentStyles.website}
                          item
                          xs={3}
                          sm={3}
                          md={5}
                          lg={5}
                        >
                          <Button
                            variant="outlined"
                            size="large"
                            key={company.website}
                            href={company.website}
                            target="_blank"
                          >
                            {"Website"}
                          </Button>
                        </Grid>
                      </Grid>
                    </Stack>
                    <Stack>
                      <Grid container spacing={0}>
                        <Grid item xs={1} sm={1} md={1} lg={1}></Grid>
                        <Grid item xs={10} sm={10} md={10} lg={10}>
                          <Typography variant="body2" gutterBottom>
                            {company.details}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Stack>
                    <Stack>
                      <Grid container spacing={0}>
                        <Grid item xs={1} sm={1} md={1} lg={1}></Grid>
                        <Grid item xs={10} sm={10} md={10} lg={10}>
                          <Typography variant="body2" gutterBottom>
                            <div className={styles.locationContainer}>
                              <LocationOnIcon /> Hanover, MD
                            </div>
                          </Typography>
                        </Grid>
                      </Grid>
                    </Stack>
                    <Stack>
                      <Grid
                        container
                        spacing={0}
                        //columns={{ xs: 4, sm: 8, md: 6 }}
                      >
                        <Grid item xs={1} sm={1} md={1} lg={1}></Grid>
                        <Grid
                          sx={componentStyles.logoIcon}
                          item
                          //columns={{ xs: 4, sm: 8, md: 6 }}
                          xs={10}
                          sm={10}
                          md={4}
                          lg={5}
                        >
                          {company.category &&
                            company.category.split(",").map((value: any) => {
                              return (
                                <Chip
                                  sx={componentStyles.chip}
                                  label={value}
                                  key={value}
                                  size="small"
                                  color="primary"
                                />
                              );
                            })}
                        </Grid>
                        <Grid
                          sx={componentStyles.logoIcon}
                          item
                          //columns={{ xs: 4, sm: 8, md: 12 }}
                          xs={10}
                          sm={10}
                          md={5}
                          lg={5}
                        >
                          {company.services &&
                            company.services.split(",").map((value: any) => {
                              return (
                                <Chip
                                  sx={componentStyles.chip}
                                  key={value}
                                  label={value}
                                  size="small"
                                  color="primary"
                                  variant="outlined"
                                />
                              );
                            })}
                        </Grid>
                      </Grid>
                    </Stack>
                  </Card> */}
                </>
              );
            })
          ) : (
            <div style={componentStyles.noDataContent}>
              {" "}
              Your search did not match any company name.{" "}
            </div>
          )}

          {companyListData &&
            companyListData.data &&
            companyListData.data.length > 0 && (
              <Stack spacing={2}>
                <Pagination
                  count={
                    companyListData.pagination &&
                    companyListData.pagination.lastPage
                  }
                  onChange={onPageChange}
                  variant="outlined"
                  shape="rounded"
                />
              </Stack>
            )}
        </Paper>
      </Grid>
    </Grid>
  );
}

export default MainContainer;
