import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState, AppThunk } from "./store";
import {
  initializeClientAPI,
  signIn,
  signUp,
  confirmEmail,
} from "./api/userAPI";
//import { Interface } from "readline";
import { GetLoginInterface } from "../interfaces/login.interfaces";
import {
  GetUserInterface,
  GetInitializeInterface,
} from "../interfaces/user.interfaces";
import { GetLoginConfirmInterface } from "../interfaces/confirm.interfaces";

export interface StateInterface {
  apiStatus: "" | "fulfilled" | "success" | "loading" | "failed" | "api-error";
  loginStatus:
    | ""
    | "fulfilled"
    | "success"
    | "loading"
    | "failed"
    | "login-error";
  registerStatus:
    | ""
    | "fulfilled"
    | "success"
    | "loading"
    | "failed"
    | "register-error";
  confirmStatus:
    | ""
    | "fulfilled"
    | "success"
    | "loading"
    | "failed"
    | "confirm-error";
  isLoggedIn: boolean;
  apiToken: string;
  apiInitialized: boolean;
  accessToken: string;
  refreshToken: string;
  userProfile: object;
  loginMessage: string;
  registerMessage: string;
  confirmMessage: string;
  emailConfirmed: boolean;
}

const initialState: StateInterface = {
  apiStatus: "loading",
  loginStatus: "fulfilled",
  registerStatus: "fulfilled",
  confirmStatus: "fulfilled",
  isLoggedIn: false,
  apiToken: "",
  apiInitialized: false,
  accessToken: "",
  refreshToken: "",
  userProfile: {},
  loginMessage: "",
  registerMessage: "",
  confirmMessage: "",
  emailConfirmed: false,
};

export const initializeClient = createAsyncThunk(
  "user/initializeClient",
  async () => {
    try {
      const response = await initializeClientAPI();
      console.log("response", response);
      return response.data;
    } catch (error) {
      console.log("Init Error:", error);
    }
  }
);

export const loginUser = createAsyncThunk(
  "user/login",
  async (data: GetLoginInterface) => {
    const response = await signIn(data);
    return response.data;
  }
);

export const registerUser = createAsyncThunk(
  "user/register",
  async (data: GetUserInterface) => {
    const response = await signUp(data);
    return response.data;
  }
);

export const confirmUserEmail = createAsyncThunk(
  "user/confirmEmail",
  async (data: GetLoginConfirmInterface) => {
    const response = await confirmEmail(data);
    return response.data;
  }
);

export const userSlice = createSlice({
  name: "user",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    logout: (state) => {
      state.isLoggedIn = false;
      state.emailConfirmed = false;
      state.accessToken = "";
      state.refreshToken = "";
      state.userProfile = {};
      //state.message = "";
      state.loginMessage = "";
      state.registerMessage = "";
      state.confirmMessage = "";
      //state.status = "";
      state.loginStatus = "";
      state.registerStatus = "";
      state.confirmStatus = "";
    },
    clearLoginStatus: (state) => {
      state.loginStatus = "";
      state.loginMessage = "";
      state.emailConfirmed = false;
    },
    clearRegisterStatus: (state) => {
      state.registerStatus = "";
      state.registerMessage = "";
      state.emailConfirmed = false;
    },
    clearConfirmStatus: (state) => {
      state.confirmStatus = "";
      state.confirmMessage = "";
      state.emailConfirmed = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(initializeClient.pending, (state) => {
        state.apiStatus = "loading";
      })
      .addCase(initializeClient.fulfilled, (state, action) => {
        state.apiStatus = "fulfilled";
        console.log("action.payload", action);
        console.log("initializeClient state:", state);
        if (action.payload.status === 404) {
          state.apiStatus = "api-error";
          state.apiInitialized = false;
        } else {
          console.log("State:", action.payload);
          state.apiToken = action.payload.tokenKey;
          state.apiInitialized = true;
        }
      })
      .addCase(initializeClient.rejected, (state, action) => {
        console.log("action.api.rejected1", action.error.message);
        state.apiStatus = "api-error";
        console.log("action.api.rejected2", action);
      })
      .addCase(loginUser.pending, (state) => {
        state.loginStatus = "loading";
      })
      .addCase(loginUser.fulfilled, (state, action) => {
        state.loginStatus = "fulfilled";
        console.log("action.payload", action);
        if (action.payload.status === 404) {
          state.loginStatus = "login-error";
          state.loginMessage = action.payload.message || "";
          state.isLoggedIn = false;
        } else {
          state.isLoggedIn = true;
          state.accessToken = action.payload.access_token;
          state.refreshToken = action.payload.refresh_token;
          //state.userProfile = action.payload;
        }
      })
      .addCase(loginUser.rejected, (state, action) => {
        console.log("action.rejected1", action.error.message);
        state.loginStatus = "login-error";
        state.loginMessage = action.error.message || "";
        state.isLoggedIn = false;
        console.log("action.rejected2", action);
      })
      .addCase(registerUser.pending, (state) => {
        state.registerStatus = "loading";
      })
      .addCase(registerUser.fulfilled, (state, action) => {
        console.log("action.payload", action.payload.status);
        if (action.payload.status === 400) {
          state.registerStatus = "register-error";
          state.registerMessage = "Email already exists";
        } else {
          state.registerStatus = "success";
        }
        //state.isLoggedIn = true;
        //state.accessToken = action.payload.access_token;
        //state.refreshToken = action.payload.refresh_token;
        //state.userProfile = action.payload;
      })
      .addCase(registerUser.rejected, (state, action) => {
        console.log("action.rejected1", action.error.message);
        state.registerStatus = "register-error";
        state.registerMessage = action.error.message || "";
        state.isLoggedIn = false;
        console.log("action.rejected2", action);
      })
      .addCase(confirmUserEmail.pending, (state) => {
        state.confirmStatus = "loading";
      })
      .addCase(confirmUserEmail.fulfilled, (state, action) => {
        console.log("action.payload", action.payload.status);
        if (action.payload.status === 400) {
          state.confirmStatus = "confirm-error";
          state.emailConfirmed = false;
          state.confirmMessage = "Email already exists";
        } else {
          state.emailConfirmed = true;
          state.confirmStatus = "success";
        }
      })
      .addCase(confirmUserEmail.rejected, (state, action) => {
        console.log("action.rejected1", action.error.message);
        state.confirmStatus = "confirm-error";
        state.confirmMessage = action.error.message || "";
        state.isLoggedIn = false;
        state.emailConfirmed = false;
        console.log("action.rejected2", action);
      });
    // .addCase(loginUser.error, (state) => {
    //   state.status = "error";
    //   console.log("action.error");
    // });
  },
});

//export const userSelector = (state) => state.user
export const {
  logout,
  clearLoginStatus,
  clearRegisterStatus,
  clearConfirmStatus,
} = userSlice.actions;

export const apiInitialized = (state: RootState) => state.user.apiInitialized;
export const apiToken = (state: RootState) => state.user.apiToken;

export const isLoggedIn = (state: RootState) => state.user.isLoggedIn;
export const accessToken = (state: RootState) => state.user.accessToken;
export const refreshToken = (state: RootState) => state.user.refreshToken;
export const emailConfirmed = (state: RootState) => state.user.emailConfirmed;
//export const status = (state: RootState) => state.user.status;

export const loginStatus = (state: RootState) => state.user.loginStatus;
export const registerStatus = (state: RootState) => state.user.registerStatus;
export const confirmStatus = (state: RootState) => state.user.confirmStatus;

export const loginMessage = (state: RootState) => state.user.loginMessage;
export const registerMessage = (state: RootState) => state.user.registerMessage;
export const confirmMessage = (state: RootState) => state.user.confirmMessage;
export const userProfile = (state: RootState) => state.user.userProfile;
export default userSlice.reducer;
