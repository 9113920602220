import axios, { AxiosPromise } from "axios";
import Config from "../../config/Config";
import { GetLoginConfirmInterface } from "../../interfaces/confirm.interfaces";

const userAPIService = axios.create();
const basePath = `${Config().envConfigModule.serverAPI}`;

export const initializeClientAPI = (): AxiosPromise => {
  //const data = JSON.stringify({ data1.username, data1.password });

  //console.log("data", data);
  //console.log("basePath", basePath);
  let data = {
    key: Config().clientID,
  };
  const config = {
    method: "post",
    url: `${basePath}/auth/initializeClient`,
    headers: {
      "Content-Type": "application/json",
      clientKey: Config().clientKey,
    },
    data: data,
  };

  //const config = {};
  return userAPIService(config);
};

export const signIn = (data: any): AxiosPromise => {
  //const data = JSON.stringify({ data1.username, data1.password });

  console.log("data", data);
  console.log("basePath", basePath);
  const config = {
    method: "post",
    url: `${basePath}/auth/login`,
    headers: {
      "Content-Type": "application/json",
      //'clientkey': Config().clientKey,
    },
    data: data,
  };

  //const config = {};
  return userAPIService(config);
};

export const signUp = (data: any): AxiosPromise => {
  //const data = JSON.stringify({ data1.username, data1.password });

  console.log("data", data);
  console.log("basePath", basePath);
  const config = {
    method: "post",
    //url: `${basePath}/user`,
    url: `${basePath}/auth/register`,
    headers: {
      "Content-Type": "application/json",
      //'clientkey': Config().clientKey,
    },
    data: data,
  };

  //const config = {};
  return userAPIService(config);
};

export const confirmEmail = (data: GetLoginConfirmInterface): AxiosPromise => {
  //const data = JSON.stringify({ data1.username, data1.password });

  console.log("data", data);
  console.log("basePath", basePath);
  const config = {
    method: "post",
    //url: `${basePath}/user`,
    url: `${basePath}/auth/confirm`,
    headers: {
      "Content-Type": "application/json",
      //'clientkey': Config().clientKey,
    },
    data: data,
  };

  //const config = {};
  return userAPIService(config);
};
