import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import counterReducer from "./counterSlice";
import userReducer from "./userSlice";
import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";
import thunk from "redux-thunk";
import { rootReducer, RootReducer } from "./reducers";

const persistConfig = {
  key: "root",
  storage,
};

// const reducer = {
//        counter: counterReducer,
//      };

//const persistedReducer = persistReducer(persistConfig, reducer);
// const persistedReducer = persistReducer<RootReducer>(
//   persistConfig,
//   rootReducer
// );

// const persistedReducer = persistReducer<RootReducer>(
//   persistConfig,
//   rootReducer
// );

const persistedReducer = persistReducer<any, any>(persistConfig, rootReducer);

//const reducer   = persistReducer(persistConfig, rootReducer) as typeof rootReducer

export const store = configureStore({
  reducer: persistedReducer, // { counter:  counterReducer },
  // reducer: {
  //   counter: counterReducer,
  //   user: userReducer,
  // },
  //devTools: process.env.NODE_ENV !== 'production',
  middleware: [thunk],
});

// export const store = configureStore({
//   reducer: {
//     counter: counterReducer,
//   },
// });

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
//export const persistor = persistStore(store);
