import React from "react";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";

import SignIn from "./login/SignIn";
import SignUp from "./login/SignUp";
import ResetPassword from "./login/ResetPassword";
import EmailSent from "./login/EmailSent";
import ConfirmEmail from "./login/ConfirmEmail";
import Dashboard from "./dashboard/Dashboard";
import Dashboard1 from "./dashboard/Dashboard1";
import DataGrid from "./sample/DataGrid";
import { Counter } from "./counter/Counter";
import Auth from "../components/auth/Auth";

// import Layout from './layout/Layout';
import { DashboardLayout } from "./layout/DashboardLayout";
import { HomeLayout } from "./layout/Home/HomeLayout";
import MainContainer from "./layout/Home/MainContainer";

export default function AppRoutes() {
  return (
    <Router>
      {/* <div> */}
      {/* <nav>
          <ul>
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <Link to="/about">About</Link>
            </li>
            <li>
              <Link to="/users">Users</Link>
            </li>
          </ul>
        </nav> */}

      <Routes>
        {/* <Route element={<Auth />}>
          <Route path="/" element={<DashboardLayout />}>
            <Route path="/" element={<Dashboard />} />
            <Route path="/dashboard" element={<Dashboard1 />} />
            <Route path="/datagrid" element={<DataGrid />} />
            <Route path="/counter" element={<Counter />} />
          </Route>
        </Route> */}
        {/* <Route  path="/layout" element={<Layout />} /> */}
        {/* <Route path="/login" element={<SignIn />} />
        <Route path="/register" element={<SignUp />} />
        <Route path="/resetpassword" element={<ResetPassword />} />
        <Route path="/confirm">
          <Route path=":tokenId" element={<ConfirmEmail />} />
        </Route> */}

        <Route path="/" element={<HomeLayout />}>
          <Route index element={<MainContainer />} />
        </Route>

        {/* <Route path="/emailsent" element={<EmailSent />} /> */}
        {/* <Route path="/about">
            <About />
          </Route>
          <Route path="/users">
            <Users />
          </Route>
          <Route path="/">
            <Home />
          </Route> */}
      </Routes>
      {/* </div> */}
    </Router>
  );
}
